/* eslint-disable react/prop-types */
import * as React from "react"
import Footer from "src/components/footer"
import ImageGrid from "src/components/image-grid"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import TwoCols from "src/components/twoCols"
import Wysiwyg from "src/components/wysiwyg"
import footerData from "src/data/global/footer"

const ServicePage = ({ pageContext }) => {
  const context = JSON.parse(JSON.stringify(pageContext))
  context.atf.isAtf = true;

  return (
    <Layout>
      <Seo title={context.meta.title} description={context.meta.description} img={context.atf.image} url={context.meta.path} />
      <TwoCols data={context.atf} />
      <Wysiwyg data={context.content} />
      {
        context.content.screenshots
        && <ImageGrid data={context.content.screenshots} />
      }
      <Footer data={footerData} hasBackground={!context.content.screenshots} />
    </Layout>
  )
}

export default ServicePage
